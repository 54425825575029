<template>
  <div class="enquetes">
    <Voltar />
    <h1 class="titulo">Enquetes</h1>
    <div :class="'home__conteudo--item home__conteudo--item-enquete ' + (!user.assinatura ? 'exlusivo-assinante' : '')" v-for="(enquete, index) in state.enquetes" :key="enquete.id">
      <span>
        <Interrogacao />
        {{ enquete.titulo }}
      </span>
      <img class="video-home" :src="enquete.imagem" :alt="enquete.titulo" />
      <p>{{ enquete.pergunta }}</p>
      <form v-if="user && user.assinatura && !state.resultado[enquete.id]">
        <input type="hidden" v-model="state.enquete.resposta" />
        <input :id="'sim-' + enquete.id" :name="'enquete-'+enquete.id" type="radio" value='resposta1' @click="state.enquete.resposta = 'resposta1'" />
        <label :for="'sim-' + enquete.id">{{ enquete.resposta1 }}</label>
        <input :id="'nao-' + enquete.id" :name="'enquete-'+enquete.id" type="radio" value='resposta2' @click="state.enquete.resposta = 'resposta2'" />
        <label :for="'nao-' + enquete.id">{{ enquete.resposta2 }}</label>
        <div class="bt bt--destaque">
          <button type="button" @click="handleSubmit(enquete.id, index)">Votar</button>
        </div>
        <div class="bt" style="display: none">
          <a @click="() => router.push({ name: 'EnquetesResultado' })">Resultado</a>
        </div>
      </form>
      <div class="grafico" v-if="state.resultado[enquete.id]">
        <p>{{ enquete.resposta1 }}</p>
        <div>
            <!-- a ideia seria que o valor do resultado da enquete seja entregue no valor do widh inline  abaixo, onde estaria o 73, assim como no span -->
            <div class="grafico__barra" :style="'width: ' + (enquete.resposta1_qtd/(enquete.resposta1_qtd + enquete.resposta2_qtd)*100).toFixed(2) + '%'"></div>
        </div>
        <span class="grafico__valor">
            {{ (enquete.resposta1_qtd/(enquete.resposta1_qtd + enquete.resposta2_qtd)*100).toFixed(1) }}%
        </span>

        <p>{{ enquete.resposta2 }}</p>
        <div>
            <!-- a ideia seria que o valor do resultado da enquete seja entregue no valor do widh inline  abaixo, onde estaria o 27, assim como no span -->
            <div class="grafico__barra" :style="'width: ' + (enquete.resposta2_qtd/(enquete.resposta1_qtd + enquete.resposta2_qtd)*100).toFixed(2) + '%'"></div>
        </div>
        <span class="grafico__valor">
            {{ (enquete.resposta2_qtd/(enquete.resposta1_qtd + enquete.resposta2_qtd)*100).toFixed(1) }}%
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
import Interrogacao from '../../components/Interrogacao'
export default {
  setup () {
    const router = useRouter()
    const toast = useToast()
    const user = JSON.parse(window.localStorage.getItem('user'))

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      enquetes: {},
      enquete: {},
      resultado: {}
    })

    async function getEnquetes () {
      try {
        state.isLoading = true
        const { data, errors } = await services.enquetes.index({ token: user.token })

        if (!errors) {
          state.enquetes = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401 || errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os enquetes!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getEnquetes()

    async function handleSubmit (enquete, index) {
      toast.clear()
      try {
        state.isLoading = true
        const { data, errors } = await services.enquetes.vote({
          enqueteId: enquete,
          resposta: state.enquete.resposta,
          token: user.token
        })

        if (!errors && !data.error_message) {
          toast.success('Obrigado! Voto Enviado com Sucesso!')
          state.resultado[enquete] = data
          state.enquetes[index] = data
        } else {
          toast.error('Não foi possível enviar seu Voto! Entre em contato com o Administrador! - 1')
        }

        state.isLoading = false
      } catch (error) {
        toast.error('Não foi possível enviar seu Voto! Entre em contato com o Administrador! - 2')
        console.log(error)
        state.isLoading = false
        state.hasErrors = !!error
      }
    }

    return {
      router,
      state,
      handleSubmit,
      user
    }
  },
  components: { Voltar, Interrogacao }
}
</script>
