<template>
  <svg viewBox="0 0 157.85 157.85">
    <title>interroga</title>
    <g id="Camada_2" data-name="Camada 2">
      <g id="Camada_1-2" data-name="Camada 1">
        <path
          class="cls-1"
          d="M78.92,0a78.92,78.92,0,1,0,78.92,78.92A78.92,78.92,0,0,0,78.92,0Zm0,147.07a68.15,68.15,0,1,1,68.15-68.15A68.15,68.15,0,0,1,78.92,147.07Z"
        />
        <path
          class="cls-1"
          d="M66.84,97.72,66.7,94c-.43-7.29,2-14.73,8.44-22.45,4.58-5.43,8.29-10,8.29-14.87S80.14,48.38,73,48.1a27,27,0,0,0-14.16,4.29L54,36.8c5.15-3,13.73-5.86,23.88-5.86,18.88,0,27.46,10.44,27.46,22.31,0,10.87-6.72,18-12.16,24s-7.44,11.44-7.29,17.88v2.57Zm-3.72,19.74c0-7.58,5.29-13,12.73-13,7.72,0,12.73,5.43,12.87,13s-5.15,13-12.87,13S63.12,124.9,63.12,117.46Z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {}
</script>
